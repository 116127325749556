export enum BillingSettingKey {
    IsAlternativeMonetaGatewaySet = "IS_ALTERNATIVE_MONETA_GATEWAY_SET",
    NonRussianPaymentScoring = "NON_RUSSIAN_PAYMENT_SCORING",
    NonRussianPaymentCancellationScoring = "NON_RUSSIAN_PAYMENT_CANCELLATION_SCORING",
    PaymentCancellationScoring = "PAYMENT_CANCELLATION_SCORING",
    PaymentScoringThreshold = "PAYMENT_SCORING_THRESHOLD",
    SuspiciousUserDefinedTextRegex = "SUSPICIOUS_USER_DEFINED_TEXT_REGEX",
    UsaCanadaPaymentAutoCancellation = "USA_CANADA_PAYMENT_AUTO_CANCELLATION",
    UsaCanadaPaymentScoring = "USA_CANADA_PAYMENT_SCORING",
    UsaCanadaPaymentCancellationScoring = "USA_CANADA_PAYMENT_CANCELLATION_SCORING",
}

export const ALL_BILLING_SETTING_KEYS = [
    BillingSettingKey.IsAlternativeMonetaGatewaySet,
    BillingSettingKey.NonRussianPaymentScoring,
    BillingSettingKey.NonRussianPaymentCancellationScoring,
    BillingSettingKey.PaymentCancellationScoring,
    BillingSettingKey.PaymentScoringThreshold,
    BillingSettingKey.SuspiciousUserDefinedTextRegex,
    BillingSettingKey.UsaCanadaPaymentAutoCancellation,
    BillingSettingKey.UsaCanadaPaymentScoring,
    BillingSettingKey.UsaCanadaPaymentCancellationScoring,
];

export interface BillingSetting {
    id: BillingSettingKey;
    value: string;
}

export interface Feature {
    id: string;
    enabled: boolean;
}

export interface OrderListItemResponse {
    createdAt: string;
    refId: string;
    type: string;
    state: {
        genericState: string;
        subState: string;
    };

    data: {
        withdrawal: {
            amount: number
        }
    };

    extended: {
        methodName: string;
    };

    owner: {
        name: string;
        refId: string;
    };
}

export interface OrderListItem {
    amount: number;
    createdAt: Date;
    methodName: string;
    refId: string;
    genericState: string;
    subState: string;
    stateName: string;
    streamerName: string;
    streamerRefId: string;
    typeName: string;

    setGenericState(newState: string): OrderListItem
    setSubState(newState: string): OrderListItem
}

export class OrderListItemImpl implements OrderListItem {

    public static fromOrderListItemResponse(item: OrderListItemResponse): OrderListItem {
        return new OrderListItemImpl(
            item.data.withdrawal.amount,
            new Date(item.createdAt),
            item.extended.methodName,
            item.refId,
            item.state.genericState,
            item.state.subState,
            item.owner.name,
            item.owner.refId,
            item.type);
    }

    public get amount(): number {
        return this._amount;
    }

    public get createdAt(): Date {
        return this._createdAt;
    }

    public get refId(): string {
        return this._refId;
    }

    public get methodName(): string {
        return this._methodName;
    }

    public get genericState(): string {
        return this._genericState;
    }

    public get subState(): string {
        return this._subState;
    }

    public get stateName(): string {
        switch (this._subState) {
            case "FRAUD_SCREENING/SUSPICIOUS": return "на проверке";
            case "FRAUD_SCREENING/SUSPENDED": return "заморожена";
        }

        switch (this._genericState) {
            case "Cancelled": return "отменена";
            case "Completed": return "завершена";
        }

        return (this._subState || this._genericState);
    }

    public get streamerName(): string {
        return this._streamerName;
    }

    public get streamerRefId(): string {
        return this._streamerRefId;
    }

    public get typeName(): string {
        switch (this._type) {
            case "WITHDRAWAL": return "Вывод";
        }

        return this._type;
    }

    public setGenericState(newState: string): OrderListItem {
        return new OrderListItemImpl(
            this._amount,
            this._createdAt,
            this._methodName,
            this._refId,
            newState,
            this._subState,
            this._streamerName,
            this._streamerRefId,
            this._type);
    }

    public setSubState(newState: string): OrderListItem {
        return new OrderListItemImpl(
            this._amount,
            this._createdAt,
            this._methodName,
            this._refId,
            this._genericState,
            newState,
            this._streamerName,
            this._streamerRefId,
            this._type);
    }

    private constructor(
            amount: number,
            createdAt: Date,
            methodName: string,
            refId: string,
            genericState: string,
            subState: string,
            streamerName: string,
            streamerRefId: string,
            type: string) {
        this._amount = amount;
        this._createdAt = createdAt;
        this._methodName = methodName;
        this._refId = refId;
        this._genericState = genericState;
        this._subState = subState;
        this._streamerName = streamerName;
        this._streamerRefId = streamerRefId;
        this._type = type;
    }

    private readonly _amount: number;
    private readonly _createdAt: Date;
    private readonly _methodName: string;
    private readonly _refId: string;
    private readonly _genericState: string;
    private readonly _subState: string;
    private readonly _streamerName: string;
    private readonly _streamerRefId: string;
    private readonly _type: string;
}

export interface SetOrderStateRequest {
    state: string;
}

export interface Social {
    type: string;
    url: string;
}

export interface Tariff {
    name: string;
    in: {
        rates: {
            CARD: any
        }
    }
}

export interface Transaction {
    refId: string;
    amount: number;
    commission: number;
    method: string;
    date: Date;
    number: string;
    signature: string;
    message: string;

    payout: boolean;
}

export enum UserFraudScreeningStatus {
    Blocked= "BLOCKED",
    Checked = "CHECKED",
    Checking = "CHECKING",
    Unchecked = "UNCHECKED"
}

export interface UserVerification {
    state: VerificationState;
    errors?: string[];
}

export interface UserListItem {
    refId: string;
    name: string;
    displayName: string;
    verified: boolean;
    socialType: string;
    registeredAt: Date;
    status: boolean;
    donatedTotal: number;
}

export interface UserProfile {
    refId: string;
    name: string;
    displayName: string;
    picture?: string;
    socials: Social[];
    features: Feature[];

    registeredAt: Date;
    blockedAt?: Date;

    email?: string;
    phone?: string;

    wallet: Wallet;
    tariff: Tariff;
    verification?: UserVerification;
}

export interface UserFraudScreeningState {
    status: UserFraudScreeningStatus;

    incomingScoring: {
        cancelled: boolean;
        cancelledNonRussian: boolean;
        nonRussian: boolean;
        score: number;
    };

    isSerialAntifraudEnabled: boolean;
}

export enum VerificationState {
    PASSPORT = "PASSPORT",
    PHONE = "PHONE",
    CHECKING = "CHECKING",
    VERIFIED = "VERIFIED",
    ERROR = "ERROR"
}

export interface Wallet {
    refId: string;
    number: string;
}

export enum BillingSystem {
    MonetaLegacy = "MONETA_LEGACY",
    MonetaTransit = "MONETA_TRANSIT",
    Paygine = "PAYGINE",
}

export interface UserBillingState {
    billingSystem: BillingSystem;
    fraudScreening: UserFraudScreeningState;
}
