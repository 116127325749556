import React, {FormEvent, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';

import {
    BillingSystem, UserBillingState, UserFraudScreeningStatus, UserProfile, UserVerification, VerificationState
} from "../../../api/models";
import apiClient from "../../../api";
import {Grid, MenuItem, Select} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    href: {
        textDecoration: "none",
        color: "black"
    }
}));

interface ProfileInfoProps {
    user: UserProfile
    userBilling: UserBillingState
}

function TextInfo(props: { label: string, value?: string }) {
    return <div>
        <Typography component="span" variant="body1" color="textSecondary">
            {`${props.label}:`}
        </Typography>
        <Typography component="span" variant="body1">
            {` ${props.value || ""}`}
        </Typography>
    </div>
}

function renderVerificationState(verification?: UserVerification) {
    if (verification) {
        switch (verification.state) {
            case VerificationState.CHECKING:
            case VerificationState.PASSPORT:
                return "проверка паспортных данных";

            case VerificationState.PHONE:
                return "проверка телефона";

            case VerificationState.ERROR:
                return `ошибка. ${verification.errors?.join(" ")}`;

            case VerificationState.VERIFIED:
                return "✓";
        }
    }

    return "";
}

function renderFraudScreeningStatus(state: UserFraudScreeningStatus) {
    switch (state) {
        case UserFraudScreeningStatus.Blocked:
            return "заблокирован";

        case UserFraudScreeningStatus.Checked:
            return "проверенный";

        case UserFraudScreeningStatus.Checking:
            return "на проверке";

        case UserFraudScreeningStatus.Unchecked:
            return "непроверенный";
    }

    return "";
}

function renderIncomingScoringStatus(
        description: string,
        flag: boolean,
        callback: (_: boolean) => Promise<void>) {

    const button =
        flag ?
            <Button size="small" variant="contained" color="primary"
                    disabled={!flag}
                    onClick={async () => await callback(false)}>
                Выключить
            </Button> :
            <Button size="small" variant="contained" color="primary"
                    disabled={flag}
                    onClick={async () => await callback(true)}>
                Включить
            </Button>;

    return <React.Fragment>
        <TextInfo label={description} value={flag ? "вкл." : "выкл."}/>

        <div style={{"paddingTop": "10px", "paddingBottom": "20px"}}>
            {button}
        </div>
    </React.Fragment>;
}

function renderSerialAntiFraudButtons(isEnabled: boolean, setState: (_: boolean) => Promise<void>) {
    if (isEnabled) {
        return (
            <Button size="small" variant="contained" color="primary"
                    onClick={async () => await setState(false)}>
                Выключить
            </Button>
        );
    }

    return (
        <Button size="small" variant="contained" color="primary"
                onClick={async () => await setState(true)}>
            Включить
        </Button>
    );
}

export default function ProfileInfo(props: ProfileInfoProps) {
    const classes = useStyles();
    const user = props.user;
    const userBilling = props.userBilling;
    const link = `https://donatty.com/${user.name}`;

    const [blocked, setBlocked] = useState<boolean>(user.blockedAt != null);

    const [userBillingState, setUserBillingState] = useState<UserBillingState>(userBilling);

    const [billingSavedState, setBillingSavedState] = useState<{ savedBillingSystem: BillingSystem, loading: boolean }>({
        savedBillingSystem: userBilling.billingSystem,
        loading: false
    });

    const [isGpayEnabled, setGPayEnabled] = useState<boolean>(
        user.features
            .find(f => f.id === "google-pay")
            ?.enabled
        ?? false);

    const [isApplePayEnabled, setApplePayEnabled] = useState<boolean>(
        user.features
            .find(f => f.id === "apple-pay")
            ?.enabled
        ?? false);

    const handleBlockUser = async () => {
        await apiClient.moderateUser(user.refId, !blocked);
        setBlocked(!blocked);
    };

    const handleEnableGpay = async () => {
        await apiClient.setGpayEnabled(user.refId, !isGpayEnabled);
        setGPayEnabled(!isGpayEnabled);
    };

    const handleEnableApplePay = async () => {
        await apiClient.setApplePayEnabled(user.refId, !isApplePayEnabled);
        setApplePayEnabled(!isApplePayEnabled);
    };

    const handleResetFraudScreeningIncomingScore = async () => {
        const newBilling = await apiClient.setUserBillingState(
            user.refId, {
                fraudScreening: {
                    incomingScoring: {score: 0}
                }
            });

        setUserBillingState(newBilling);
    };

    const handleSetFraudScreeningStatus = async (status: UserFraudScreeningStatus) => {
        const newBilling = await apiClient.setUserBillingState(user.refId, {fraudScreening: {status}});
        setUserBillingState(newBilling);
    };

    const handleSetFraudScreeningCancelled = async (cancelled: boolean) => {
        const newBilling = await apiClient.setUserBillingState(
            user.refId, {
                fraudScreening: {
                    incomingScoring: {cancelled}
                }
            });

        setUserBillingState(newBilling);
    };

    const handleSetFraudScreeningCancelledNonRussian = async (cancelledNonRussian: boolean) => {
        const newBilling = await apiClient.setUserBillingState(
            user.refId, {
                fraudScreening: {
                    incomingScoring: {cancelledNonRussian}
                }
            });

        setUserBillingState(newBilling);
    };

    const handleSetFraudScreeningNonRussian = async (nonRussian: boolean) => {
        const newBilling = await apiClient.setUserBillingState(user.refId, {
            fraudScreening: {incomingScoring: {nonRussian}}
        });

        setUserBillingState(newBilling);
    };

    const handleSetFraudScreeningSerialAntifraud = async (isSerialAntifraudEnabled: boolean) => {
        const newBilling = await apiClient.setUserBillingState(user.refId, {
            fraudScreening: {isSerialAntifraudEnabled}
        });
        setUserBillingState(newBilling);
    };

    const handleBillingSystemChange = (billingSystem: BillingSystem) => {
        setUserBillingState({...userBillingState, billingSystem})
    };

    const handleSaveBillingSystem = async (e: FormEvent<any>) => {
        e.preventDefault();
        setBillingSavedState({
            savedBillingSystem: userBillingState.billingSystem,
            loading: true,
        });
        const billingSystem: BillingSystem = new FormData(e.currentTarget).get("billingSystem") as BillingSystem;
        const newBilling = await apiClient.setUserBillingState(user.refId, {billingSystem});
        setUserBillingState(newBilling);
        setBillingSavedState({
            savedBillingSystem: userBillingState.billingSystem,
            loading: false,
        });
    };

    return (
        <React.Fragment>
            <div>
                <Typography component="span" variant="body1" color="textSecondary">
                    имя:
                </Typography>
                <a className={classes.href} href={link} target="_blank">
                    <Typography component="span" variant="body1">
                        {` ${user.displayName || ""}`}
                    </Typography>
                </a>
            </div>
            {user.email && <TextInfo label="e-mail" value={user.email}/>}
            {user.phone && <TextInfo label="тел" value={user.phone}/>}
            <TextInfo label="тариф" value={user.tariff.name}/>
            <TextInfo label="ном. счета" value={user.wallet.number}/>
            <TextInfo label="уприд" value={renderVerificationState(user.verification)}/>
            <TextInfo label="соц.cети" value=""/>
            {user.socials.filter(it => it.url > "").map(it => {
                return <div key={it.url}>
                    <Typography component="span" variant="body1" color="textSecondary">
                        {`${it.type}:`}
                    </Typography>
                    {it.url && <a target="_blank" className={classes.href} href={it.url}>
                        <Typography noWrap component="span" variant="body1">
                            {` ${it.url}`}
                        </Typography>
                    </a>}
                </div>
            })}

            <TextInfo label="зарегистрирован" value={user.registeredAt.toLocaleDateString()}/>
            <div style={{"paddingTop": "10px", "paddingBottom": "20px"}}>
                <Button onClick={handleBlockUser} size="small"
                        variant="contained" color={blocked ? "primary" : "secondary"}>
                    {blocked ? "разблокировать" : "заблокировать"}
                </Button>
                &nbsp;&nbsp;
                <Button onClick={handleEnableGpay} size="small" variant="contained" color="primary">
                    {isGpayEnabled ? "выкл gpay" : "вкл gpay"}
                </Button>
                &nbsp;&nbsp;
                <Button onClick={handleEnableApplePay} size="small" variant="contained" color="primary">
                    {isApplePayEnabled ? "выкл applepay" : "вкл applepay"}
                </Button>
            </div>
            <div style={{"paddingTop": "10px", "paddingBottom": "20px"}}>
                <form onSubmit={e => handleSaveBillingSystem(e)}>
                    <TextInfo label="Платёжный провайдер" value={(billingSavedState.savedBillingSystem)}/>
                    <Select name="billingSystem" id="demo-simple-select" labelId="billing-system-select"
                            value={userBillingState.billingSystem} label="Платёжный провайдер"
                            onChange={e => handleBillingSystemChange(e.target.value as BillingSystem)}>
                        <MenuItem value={BillingSystem.MonetaLegacy}>Монета/Кошельки</MenuItem>
                        <MenuItem value={BillingSystem.MonetaTransit}>Монета/Транзит</MenuItem>
                        <MenuItem value={BillingSystem.Paygine}>Paygine</MenuItem>
                    </Select>
                    &nbsp;&nbsp;
                    <Button type="submit" size="small" variant="contained" color="primary"
                            disabled={billingSavedState.loading || billingSavedState.savedBillingSystem === userBillingState.billingSystem}>
                        Переключить
                    </Button>
                </form>
            </div>
            <TextInfo label="скоринг донатов"
                      value={`${userBillingState.fraudScreening.incomingScoring.score} очков`}/>
            <div style={{"paddingTop": "10px", "paddingBottom": "20px"}}>
                <Button size="small" variant="contained" color="primary"
                        onClick={handleResetFraudScreeningIncomingScore}>
                    сбросить
                </Button>
            </div>

            <TextInfo label="антифрод статус"
                      value={renderFraudScreeningStatus(userBillingState.fraudScreening.status)}/>
            <div style={{"paddingTop": "10px", "paddingBottom": "20px"}}>
                <Button size="small" variant="contained" color="secondary"
                        onClick={async () => {
                            await handleSetFraudScreeningStatus(UserFraudScreeningStatus.Unchecked);
                        }}
                        disabled={userBillingState.fraudScreening.status === UserFraudScreeningStatus.Unchecked}>
                    Непроверенный
                </Button>
                &nbsp;&nbsp;

                <Button size="small" variant="contained" color="secondary"
                        disabled={userBillingState.fraudScreening.status === UserFraudScreeningStatus.Checking}
                        onClick={async () => {
                            await handleSetFraudScreeningStatus(UserFraudScreeningStatus.Checking);
                        }}>
                    На проверке
                </Button>
                &nbsp;&nbsp;

                <Button size="small" variant="contained" color="secondary"
                        disabled={userBillingState.fraudScreening.status === UserFraudScreeningStatus.Blocked}
                        onClick={async () => {
                            await handleSetFraudScreeningStatus(UserFraudScreeningStatus.Blocked);
                        }}>
                    Заблокирован
                </Button>
                &nbsp;&nbsp;

                <Button size="small" variant="contained" color="primary"
                        disabled={userBillingState.fraudScreening.status === UserFraudScreeningStatus.Checked}
                        onClick={async () => {
                            await handleSetFraudScreeningStatus(UserFraudScreeningStatus.Checked);
                        }}>
                    Проверенный
                </Button>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {renderIncomingScoringStatus(
                        "скоринг входящих донатов (отмена)",
                        userBillingState.fraudScreening.incomingScoring.cancelled,
                        handleSetFraudScreeningCancelled)}
                </Grid>

                <Grid item xs={6}>
                    {renderIncomingScoringStatus(
                        "скоринг входящих донатов (отмена не Россия)",
                        userBillingState.fraudScreening.incomingScoring.cancelledNonRussian,
                        handleSetFraudScreeningCancelledNonRussian)}
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {renderIncomingScoringStatus(
                        "скоринг входящих донатов (донат не Россия)",
                        userBillingState.fraudScreening.incomingScoring.nonRussian,
                        handleSetFraudScreeningNonRussian)}
                </Grid>

                <Grid item xs={6}>
                    <TextInfo label="серийный антифрод"
                              value={userBillingState.fraudScreening.isSerialAntifraudEnabled ? "вкл." : "выкл."}/>

                    <div style={{"paddingTop": "10px", "paddingBottom": "20px"}}>
                        {renderSerialAntiFraudButtons(
                            userBillingState.fraudScreening.isSerialAntifraudEnabled,
                            handleSetFraudScreeningSerialAntifraud)}
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
